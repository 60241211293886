import { Box } from "@mui/material"
import { CustomModal, RotatedErrorOutlineIcon, styles } from "./CustomModal"
import { CustomButton } from "./CustomButton"
import { useNavigate } from "react-router-dom"

export const SoftDeleteModal = ({
    isOpen,
    handleOpen,
    handleClose,
    header,
    onClick,
}) => {
    const navigate = useNavigate();

    const handleOnClick = async () => {
        onClick()
    }

    return (
        <CustomModal
            isOpen={isOpen}
            onClose={handleClose}
            header={header || "Delete Confirmation"}>
            <Box>
                <div style={styles.contentContainer}>
                    <RotatedErrorOutlineIcon />
                    <p style={styles.modalBody}>
                        Are you sure you want to delete this form
                    </p>
                </div>
                <div style={styles.buttonContainer}>
                    <CustomButton color="secondary" onClick={handleOnClick}>Yes</CustomButton>
                    <CustomButton onClick={handleClose}>No</CustomButton>
                </div>
            </Box>
        </CustomModal>
    )
}