import { Box } from "@mui/material"
import { CustomModal, RotatedErrorOutlineIcon, styles } from "./CustomModal"
import { CustomButton } from "./CustomButton"
import { useNavigate } from "react-router-dom"

export const SubmitConfirmModal = ({
    isOpen,
    handleOpen,
    handleClose,
    header,
    savePatientData,
}) => {
    const navigate = useNavigate();

    const handleSavePatientData = async () => {
        await savePatientData(true)
        navigate("/home")
    }

    return (
        <CustomModal
            isOpen={isOpen}
            onClose={handleClose}
            header={header || "Submit Confirmation"}>
            <Box>
                <div style={styles.contentContainer}>
                    <RotatedErrorOutlineIcon />
                    <p style={styles.modalBody}>
                        Are you sure you want to submit? After submission, this form cannot be edited
                    </p>
                </div>
                <div style={styles.buttonContainer}>
                    <CustomButton onClick={handleSavePatientData}>SUBMIT</CustomButton>
                </div>
            </Box>
        </CustomModal>
    )
}