import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useNavigate } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'
import { setAddPatientModal } from 'Services/actions'
import { Button } from '@mui/material'

const BackButton = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const prevRoute = useSelector((state) => state.setPreviousRouteOfFormView)

    const handleBackClick = () => {

        if (prevRoute === 'patientFromUserList') {
            navigate('/home')
        } else if (prevRoute === 'patientFromSearch') {
            dispatch(setAddPatientModal(true))
            navigate('/home')
        } else {
            navigate('/home')
        }
    }

    return (
        <a
            name='CE1ID_ArrowButton'
        >
            <ArrowBackIcon
                style={styles.arrowStyle}
                onClick={handleBackClick}
            />
        </a>

    )
}

const styles = {
    arrowStyle: {
        fill: '#1A485E',
        cursor: 'pointer',
    },
}

export default BackButton
