import { useState, useEffect, useRef } from 'react'
import { userRequest } from 'userRequest'

const SearchPatientList = () => {
    const [searchQuery, setSearchQuery] = useState('')
    const [parameterSearchQuery, setParameterSearchQuery] = useState('')
    const [users, setUsers] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)

    const parameterRef = useRef('')

    const regexLetter = /.*[a-zA-Z].*/
    const regexMedicareNo = /^\d+$/
    const regexFormattedDate = /^\d{4}-\d{2}-\d{2}$/
    const regexDate = /^\d{2}\/\d{2}\/\d{4}$/

    const getPatient = async () => {
        if (regexDate.test(parameterSearchQuery)) {
            const [day, month, year] = parameterSearchQuery.split('/')
            const convertedDate = `${year}-${month}-${day}`
            setParameterSearchQuery(convertedDate)
            parameterRef.current = 'dob'
        } else if (regexFormattedDate.test(parameterSearchQuery)) {
            parameterRef.current = 'dob'
        } else if (regexMedicareNo.test(parameterSearchQuery)) {
            parameterRef.current = 'medicareNumber'
        } else if (regexLetter.test(parameterSearchQuery)) {
            parameterRef.current = 'surname'
        } else {
            setIsLoading(false)
            setError('Invalid search query')
            return
        }

        setIsLoading(true)
        setError(null)

        try {
            const response = await userRequest.get(
                `/PatientAPI/FilterPatient?${parameterRef.current}=${parameterSearchQuery}&includeInactive=false&includeDeceased=false`
            )

            if (response.data.length > 0) {
                setUsers(response.data)
                setIsLoading(false)
                setError(null)
            } else {
                setUsers([])
                setIsLoading(false)
                setError('No patient found')
            }
        } catch (error) {
            setError('An error occurred while fetching data')
            setIsLoading(false)
            console.error(error)
        }
    }

    useEffect(() => {
        if (parameterSearchQuery.trim() === '') {
            setUsers([])
            setIsLoading(false)
            setError(null)
            return
        }

        const delay = setTimeout(() => {
            getPatient()
        }, 1000)

        return () => clearTimeout(delay)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parameterSearchQuery])

    const handleSearch = (event) => {
        setSearchQuery(event.target.value)
        setParameterSearchQuery(event.target.value)
    }

    return { searchQuery, users, isLoading, error, handleSearch }
}

export default SearchPatientList
