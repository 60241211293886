import { Box, Modal, Button, styled } from '@mui/material'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import CloseIcon from '@mui/icons-material/Close'

export const RotatedErrorOutlineIcon = styled(ErrorOutlineIcon)({
    transform: 'rotate(180deg)',
    color: '#757575',
    width: '40px',
    height: '40px',
})

export const CustomModal = ({ isOpen, onClose, header, children }) => {
    return (
        <Modal
            open={isOpen}
            onClose={onClose}
        >
            <Box style={styles.modal}>
                <Box style={styles.modalHeader}>
                    <p style={styles.heading}>{header}</p>
                    <Button
                        name="CE1ID_PatientSearchCloseButton"
                        onClick={onClose}
                        style={styles.closeIcon}
                    >
                        <CloseIcon
                            id="closeButton"
                            name="CE1ID_PatientSearchCloseButton"
                            sx={{ color: "#FFFFFF" }}
                            style={{ zIndex: '-1' }}
                        />
                    </Button>
                </Box>

                {children}
            </Box>
        </Modal>
    )
}

export const styles = {
    modalBody: {
        // height: '57px',
        color: '#1A485E',
        fontSize: '20px',
        fontWeight: '600',
        lineHeight: '30px',
        letterSpacing: '0.15px',
        padding: '0px',
        margin: '0px',
    },
    heading: {
        padding: '0px',
        margin: '0px',
        fontSize: '20px',
        fontWeight: '700',
        color: '#FFFFFF',
        lineHeight: '24px',
        letterSpacing: '0.15px',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'end',
        margin: '47px 39px 36px 0px',
        gap: '14px',
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'row',
        margin: '40px 46px 0px 24px',
        alignItems: 'center',
        gap: '19px',
    },
    modal: {
        position: 'absolute',
        top: '184px',
        left: '50%',
        transform: 'translateX(-50%)',
        minWidth: '53vw',
        maxHeight: 'calc(100vh - 40px)',
        overflow: 'auto',
        backgroundColor: '#fff',
        outline: 'none',
        borderRadius: '8px',
    },
    modalHeader: {
        padding: '19px 22px 11px 24px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: '#1A485E',
    },
    closeIcon: {
        padding: 0,
        fontSize: '32px',
        minWidth: 'auto',
        width: '30px',
        height: '30px',
        fontWeight: '500',
        zIndex: '1',
    },
}
