import { useState } from 'react'
import { Box, Button, Modal, Divider } from '@mui/material'

import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import AddPatientFormSearch from './AddPatientFormSearch'
import SearchModal from './SearchModal'

import { useDispatch, useSelector } from 'react-redux'
import { setAddPatientModal } from 'Services/actions'

const AddPatient = () => {
    const dispatch = useDispatch();
    const isModalOpen = useSelector((state) => state.setAddPatientFormReducerView);
    const [clickedPatient, setClickedPatient] = useState(null)

    const openModal = () => {
        dispatch(setAddPatientModal(true));
    }
    const closeModal = () => {
        dispatch(setAddPatientModal(false));
    }

    return (
        <>
            <Box style={styles.top_bar}>
                <Box>
                    <div style={styles.top_bar_heading}>Manage Patients</div>
                </Box>
                <Button
                    name='CE1ID_AddNewPatientButton'
                    style={styles.add_btn}
                    variant='outlined'
                    startIcon={<AddIcon style={{ paddingBottom: '2px' }} />}
                    onClick={openModal}
                >
                    ADD A new patient
                </Button>
            </Box>
            <Divider style={styles.horizon_line} />
            <Modal open={isModalOpen} onClose={closeModal}>
                <Box style={styles.modal}>
                    <Box style={styles.modalHeader}>
                        <Box style={styles.modalHeader_title}>
                            <div style={styles.modalHeader_title_l1}>
                                Create new patient form
                            </div>
                            <div style={styles.modalHeader_title_l2}>
                                Search for a patient already in your PMS by entering name or
                                Medicare number below
                            </div>
                        </Box>
                        <Button
                            onClick={closeModal}
                            style={styles.closeIcon}
                            name="CE1ID_AddNewPatientCloseButton"
                        >
                            <CloseIcon
                                sx={{ color: "rgb(26, 72, 94)" }}
                                style={{ zIndex: -2 }}
                            />
                        </Button>
                    </Box>
                    <AddPatientFormSearch
                        setClickedPatient={setClickedPatient}
                    />
                </Box>
            </Modal>
            {clickedPatient && (
                <SearchModal
                    isOpen={true}
                    data={clickedPatient}
                    onClose={() => setClickedPatient(null)}
                />
            )}
        </>
    )
}

const styles = {
    add_btn: {
        color: '#ffffff',
        backgroundColor: '#1A485E',
        border: 'none',
        width: '224px',
        height: '43px',
    },
    top_bar: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    top_bar_heading: {
        fontSize: '20px',
        fontWeight: '600',
        color: '#1A485E',
    },
    modal: {
        position: 'absolute',
        top: '184px',
        left: '50%',
        transform: 'translateX(-50%)',
        minWidth: '80vw',
        maxHeight: 'calc(100vh - 40px)',
        backgroundColor: '#fff',
        padding: '56px 50px 90px 39px',
        outline: 'none',
        borderRadius: '8px',
    },

    modalHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '10px',
    },
    modalHeader_title: {
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
    },
    modalHeader_title_l1: {
        fontSize: '24px',
        fontWeight: '600',
        color: '#1A485E',
        paddingBottom: '8px',
    },
    modalHeader_title_l2: {
        fontSize: '11.35px',
        fontWeight: '500',
        color: '#A6A6A6',
    },
    closeIcon: {
        paddingBottom: '23px',
        padding: 0,
        minWidth: 'auto',
        width: '30px',
        height: '30px',
    },
    horizon_line: {
        backgroundColor: '#0000001A',
        marginTop: '20px',
    },
}
export default AddPatient
