import { useEffect } from 'react'
import { useNavigate } from 'react-router'

const AutoLogout = () => {
    const navigate = useNavigate()
    useEffect(() => {
        let timeout

        const handleInactive = () => {
            localStorage.removeItem('token')
            navigate('/')
        }

        // Replace token exp (epoch time)
        timeout = setTimeout(handleInactive, 60 * 60 * 1000)

        const resetTimeout = () => {
            clearTimeout(timeout)
            timeout = setTimeout(handleInactive, 60 * 60 * 1000)
        }

        const events = ['mousedown', 'keydown', 'mousemove', 'scroll', 'touchstart']

        events.forEach((event) => {
            window.addEventListener(event, resetTimeout)
        })

        return () => {
            events.forEach((event) => {
                window.removeEventListener(event, resetTimeout)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return null
}

export default AutoLogout