import {
    Button,
    styled,
} from "@mui/material";

export const CustomButton = styled(Button)(({ color }) => ({
    ...{
        borderRadius: "4px",
        boxShadow:
            "0px 1px 5px 0px rg ba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20)",
        width: "165px",
        letterSpacing: "0.4px",

        color: "white",
        backgroundColor: "#1A485E",
        "&: hover": {
            backgroundColor: "#153d50",
        },
    },
    ...(color === "secondary" && {
        color: "#1A485E",
        backgroundColor: "#B9D4B2",
        "&: hover": {
            backgroundColor: "#91ad85",
        },
    }),
}));
