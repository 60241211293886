import { useEffect, useState } from 'react';
import jwtDecode from 'jwt-decode';
import DateTimeService from './DateTimeService';
import { userRequest2 } from 'userRequest';

const useDecodedToken = () => {
    const [decodedToken, setDecodedToken] = useState(null);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            const decoded = jwtDecode(token);
            setDecodedToken(decoded);
        }
    }, []);

    return decodedToken;
};

const useEventListener = () => {
    const decodedToken = useDecodedToken();

    const saveEvent = async (tagName, name, trackerHref, outerText) => {
        const errorMessage = "Data not retrieved";
        let createdBy = decodedToken?.sub || errorMessage;
        let userRole = decodedToken?.job_role || errorMessage;

        const obj = {
            createdBy: createdBy,
            userRole: userRole,
            createdClientDateTimeString: DateTimeService.GetDateString(),
            createdClientDateTime: new Date(),
            tagName: tagName,
            outerText: outerText,
            link: trackerHref === "javascript:;" ? " " : trackerHref,
            name: name,
        };

        try {
            await userRequest2.post('/api/Events', obj);
        } catch (error) {
            console.error('Click event was not saved', error);
        }
    };

    const handle = (event) => {
        const element = event.target;
        const testid = element.getAttribute('data-testid')

        if (testid === "ArrowBackIcon") {
            saveEvent('ArrowBackIcon', 'backButton', '', '');
        }
        if (testid === "CalendarIcon") {
            saveEvent('CalenderIcon', 'datePicker', '', '');
        }
        if (
            element.name === undefined ||
            !element.name.startsWith("CE1ID_") ||
            !element
        ) {
            return;
        }
        if (
            testid === undefined
        ) {
            return;
        }
        let name = element.name.replace("CE1ID_", "");
        if (name === "") {
            name = element.value;
        }

        let link =
            element.dataset.trackHref ||
            element.href ||
            element.getAttribute("data-hyperlink");

        saveEvent(element.tagName, name, link, element.outerText);
    };

    useEffect(() => {
        const handleClick = (event) => {
            handle(event);
        };

        document.addEventListener("click", handleClick);

        return () => {
            document.removeEventListener('click', handleClick);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [decodedToken]);

    return handle, saveEvent;
};

export { useEventListener };
export default useEventListener;