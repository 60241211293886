import React from "react";
import {
    RadioGroup,
    FormControlLabel,
} from "@mui/material";
import {
    styles,
} from "utils/QuestionsMapping";

/**
 * Note we can't pass ...props to RadioGroup as it throws invalid prop children
 */
export const ClinicalRadioGroup = ({
    field,
    formData,
    options,
    control,                // Comes from in Form
    disabled,               // Use to disable the radio button
    checked = false,        // Use to see if the radio button is checked
    onRadioClick,
    onRadioDoubleClick,
    className,
    onChange,
    onClick
}) => {
    const handleRadioClick = (field, value) => {
        if (!onRadioClick) return

        onRadioClick(field, value)
    }

    const handleRadioDoubleClick = (field, value) => {
        if (!onRadioDoubleClick) return

        onRadioDoubleClick(value)
    }

    return (
        <RadioGroup
            disabled={disabled}
            className={className}
            value={formData[field]}
            onClick={onClick}
            onChange={onChange}
        >
            <div className="flex">
                {options.map(({ value, label }) => (
                    <FormControlLabel
                        name={`CE1ID_${field}${value}`}
                        disabled={disabled}
                        style={(options && checked) ? styles.clinical_1 : styles.clinical}
                        key={value}
                        value={value}
                        onClick={(e) => handleRadioClick(field, value)}
                        // onClick={console.log(onRadioClick)}
                        onDoubleClick={(e) => handleRadioDoubleClick(field, value)}
                        control={control}
                        label={label}
                        checked={checked && formData[field].includes(value) ? true : undefined}
                    />
                ))}
            </div>
        </RadioGroup>
    )
}