import React, { useEffect, useState } from "react";
import {
    styled,
    Box,
    Button,
    Divider,
    Grid,
    Paper,
    Radio,
    Tooltip,
} from "@mui/material";
import { ClinicalRadioGroup } from "Components/Form/ClinicalRadioGroup";
import { CustomButton } from "Components/CustomButton";
import { SubmitConfirmModal } from "Components/SubmitConfirmModal";
import { SoftDeleteModal } from "Components/SoftDeleteModal";
import { ClinicalSelect } from "Components/Form/ClinicalSelect";
import { InfoOutlined } from "@mui/icons-material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
    setPatientDatas,
    updateVersion,
    setIsConsented,
    setPreviousRouteOfForm,
} from "Services/actions";
import { toast } from "react-toastify";
import NavBar from "Components/Navbar";
import BackButton from "Components/BackButton";
import History from "Components/History";
import PatientInfo from "Components/PatientInfo";
import profileIcon from "Assets/images/profile-icon.svg";
import {
    styles,
    styles1,
    styles2,
    styles3,
    questionsMapping1,
    questionsMapping3,
} from "utils/QuestionsMapping";
import { userRequest, userRequest2 } from "userRequest";
import { processFormData, areValuesValid } from "utils/FormUtils";
import { Form, Formik } from "formik";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ExportDateFetch } from "../utils/ExportDateFetch";
import { DeleteOutline } from "@mui/icons-material";

let updatedFormData;
let languages;
let completionPercentage;
let clinicalHandover;
let conditionTypes;
let disabilities;
let otherReferrals;
let noAcuteInjury;
const fields = {
    episodeEndStatus: null,
    isRegularGPAvailable: null,
    clinicalHandover: [],
    alternativePlaces: null,
    otherReferralIdsJson: [],
    conditionTypeIdsJson: [],
    disabilityStatusIdsJson: [],
    interpreterRequired: null,
    causeOfInjury: null,
    languageIdsJson: [],
    uccEntryPoint: null,
};

const QuestionOptions3 = ({
    field,
    options,
    type,
    formData,
    setFormData,
    isRequired,
    isMissing,
    isDisableForm,
    handleInputClick,
}) => {
    const [missing, setMissing] = useState(isMissing);

    updatedFormData = formData;

    languages = processFormData(formData.languageIdsJson);
    clinicalHandover = processFormData(formData.clinicalHandover);
    conditionTypes = processFormData(formData.conditionTypeIdsJson);
    disabilities = processFormData(formData.disabilityStatusIdsJson);
    otherReferrals = processFormData(formData.otherReferralIdsJson);

    const handleChange3 = (e) => {
        const updatedData = { ...formData };
        if (type === "radioGroup") {
            handleOptionChange3(field, e.target.value, updatedData);
        } else if (type === "radio") {
            updatedData[field] =
                updatedData[field] === e.target.value ? "" : e.target.value;
        } else {
            updatedData[field] = e.target.value;
        }

        if (updatedData[field] && missing) {
            setMissing(false)
        }
        setFormData(updatedData);
    };

    const handleOptionChange3 = (field, value, updatedData) => {
        if (updatedData !== undefined && updatedData !== null) {
            const updatedDataCopy = { ...updatedData };
            updatedDataCopy[field] = updatedDataCopy[field].includes(value)
                ? updatedDataCopy[field].filter((option) => option !== value)
                : [...updatedDataCopy[field], value];
            setFormData(updatedDataCopy);
        }

        if (updatedData[field] && missing) {
            setMissing(false)
        }
    };

    const handleRadioGroupClick = (field, value) => {
        const updatedData = { ...formData };
        updatedData[field] = updatedData[field].includes(value)
            ? updatedData[field].filter((option) => option !== value)
            : [...updatedData[field], value];

        if (updatedData[field] && missing) {
            setMissing(false)
        }
        setFormData(updatedData);
    };

    const renderSelectValue3 = (selected) => {
        if (Array.isArray(selected) && selected.length > 0) {
            let str = selected.join(", ");
            return str;
        } else {
            return "Select a Language";
        }
    };

    const [tooltipContent, setTooltipContent] = useState(
        renderSelectValue3(formData["languageIdsJson"])
    );

    const handleOnRadioClick = (field, value) => { 
        handleRadioGroupClick(field, value);
        handleInputClick();
    }

    useEffect(() => {
        setTooltipContent(renderSelectValue3(formData["languageIdsJson"]));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData["languageIdsJson"]]);

    /**
     * This useEffect is used to remove the red border when the user selects an option
     */
    useEffect(() => {
        setMissing(isMissing);
    }, [isMissing])

    return (
        <>
            {type === "radio" && 
                <ClinicalRadioGroup field={field} formData={formData}
                    options={options}
                    disabled={formData.isSubmitted || isDisableForm}
                    control={<Radio disabled={formData.isSubmitted || isDisableForm} />}
                    onChange={handleChange3}
                    onClick={handleInputClick}
                />}
            {type === "select" &&
                <Tooltip title={tooltipContent} placement="bottom">
                    <Box>
                        <ClinicalSelect name="CE1ID_languageSelect"
                            field={field} formData={formData}
                            options={options}
                            disabled={formData.isSubmitted || isDisableForm}
                            onClick={handleInputClick}
                            multiple={true}
                            isMissing={isRequired && isMissing}
                            onChange={handleChange3}
                            renderValue={renderSelectValue3}
                        />
                    </Box>
                </Tooltip>}
            {type === "radioGroup" &&
                <ClinicalRadioGroup field={field} formData={formData}
                    aria-label={field}
                    options={options}
                    disabled={formData.isSubmitted || isDisableForm}
                    control={<Radio disabled={formData.isSubmitted || isDisableForm}
                        icon={<PanoramaFishEyeIcon />}
                        checkedIcon={<CheckCircleIcon style={{ color: "#1976D2" }} />}
                        style={{ paddingTop: "0px" }}
                    />}
                    checked={true}
                    onRadioClick={handleOnRadioClick}
                    onRadioDoubleClick={handleRadioGroupClick}
                    onClick={handleInputClick}
                />}
        </>
    );
};

const QuestionOptions1 = ({
    field,
    options,
    type,
    formData,
    setFormData,
    isRequired,
    isMissing,
    isDisableForm,
    handleInputClick,
}) => {
    const [missing, setMissing] = useState(isMissing);

    const handleOptionChange = (value) => {
        const updatedData = { ...formData };
        updatedData[field] = updatedData[field].includes(value)
            ? updatedData[field].filter((option) => option !== value)
            : [...updatedData[field], value];
        if (field === "conditionTypeIdsJson" && noAcuteInjury && value === 1) {
            updatedData["causeOfInjury"] = "";
        }

        if (updatedData[field] && missing) {
            setMissing(false)
        }
        setFormData(updatedData);
    };

    const handleChange = (e) => {
        const updatedData = { ...formData };
        if (field === "episodeEndStatus") {
            const isNewAnswer = updatedData[field] !== e.target.value;
            if (
                isNewAnswer ||
                updatedData["treatmentEndTime3"] === "0001-01-01T00:00:00"
            ) {
                updatedData["treatmentEndTime3"] = new Date();
            }
        }
        updatedData[field] = e.target.value;

        if (updatedData[field] && missing) {
            setMissing(false)
        }
        setFormData(updatedData);
    };

    const handleOnRadioClick = (field, value) => {
        handleOptionChange(value);
        handleInputClick();
    }

    /**
     * This useEffect is used to remove the red border when the user selects an option
     */
    useEffect(() => {
        setMissing(isMissing);
    }, [isMissing])

    //if (field === "clinicalHandover") {
    //    return (
    //        <ClinicalRadioGroup field={field} formData={formData}
    //            options={options}
    //            disabled={formData.isSubmitted || isDisableForm}
    //            control={<Radio disabled={formData.isSubmitted || isDisableForm}
    //                style={{ paddingTop: "0px" }}
    //                icon={<PanoramaFishEyeIcon />}
    //                checkedIcon={<CheckCircleIcon style={{ color: "#1976D2" }} />}
    //            />}
    //            onRadioClick={handleOnRadioClick}
    //            onRadioDoubleClick={handleOptionChange}
    //            onClick={handleInputClick}
    //        />
    //    );
    //} else
    if (type === "radio") {
        return (
            <ClinicalRadioGroup field={field} formData={formData}
                options={options}
                disabled={formData.isSubmitted || isDisableForm}
                control={<Radio />}
                onChange={handleChange}
                onClick={handleInputClick}
            />
        );
    } else if (type === "select") {
        return (
            <ClinicalSelect field={field} formData={formData}
                options={options}
                disabled={formData.isSubmitted || isDisableForm}
                isMissing={isRequired && missing}
                onClick={handleInputClick}
                onChange={handleChange}
            />
        );
    } else if (type === "radioGroup") {
        return (
            <ClinicalRadioGroup field={field} formData={formData}
                aria-label={field}
                options={options}
                disabled={formData.isSubmitted || isDisableForm}
                control={<Radio 
                    icon={<PanoramaFishEyeIcon />}
                    checkedIcon={<CheckCircleIcon style={{ color: "#1976D2" }} />}
                    style={{ paddingTop: "0px" }}
                />}
                checked={true}
                onRadioClick={handleOnRadioClick}
                onRadioDoubleClick={handleOptionChange}
                onClick={handleInputClick}
            />
        );
    }
    return null;
};

const CustomDeleteButton = styled(Button)(({ bgColor }) => ({
    border: !bgColor && "2px solid #000000",
    color: bgColor ? "#FFFFFF" : "#1A485E",
    borderRadius: "4px",
    backgroundColor: bgColor || "#FFFFFF",
    boxShadow:
        "0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20)",
    width: "165px",
    letterSpacing: "0.15px",
    "&:hover": {
        backgroundColor: bgColor ? "#092939" : "#ECF9FF",
    },
}));

function PatientFormPage() {
    const values = useSelector((state) => state.setPatientDataView);
    const consent = useSelector((state) => state.isConsentedView);
    const dispatch = useDispatch();
    const [formValuesChanged, setFormValuesChanged] = useState(false);
    const [acuteClick, setAcuteClick] = useState(false);
    const [isDisableForm, setDisableForm] = useState(false);
    const [isMissing, setIsMissing] = useState({});
    const { id, patientId } = useParams();
    const navigate = useNavigate();
    const formattedDate = dayjs().format("YYYY-MM-DDTHH:mm:ss").toString();

    const [onInit, setOnInit] = useState(true);                          // Used to prevent inital render
    const [hasAttemptSubmit, setHasAttemptSubmit] = useState(false);     // Used to handle form missing required fields
    //const [showErrorModal, setShowErrorModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    //#region Backdate
    const currentDate = dayjs();
    let dateOfpresentationForDatabase;

    const [dateOfPresentation, setDateOfPresentation] = useState(currentDate);
    const [exportDate, setExportDate] = useState(null);
    const [previousWeekDate, setPreviousWeekDate] = useState(null);
    const [countDown, setCountDown] = useState(0);

    const handleDateOfPresentation = async (date) => {
        const formattedDate = dayjs(date);
        setDateOfPresentation(formattedDate);
        dateOfpresentationForDatabase = formattedDate;
        await savePatientDataToDatabase();
    };

    useEffect(() => {
        const fetch = async () => {
            const exportData = await ExportDateFetch();
            const { weekStartDateReturn, exportDateTimeReturn, countDownReturn } =
                exportData;

            setPreviousWeekDate(weekStartDateReturn);
            setExportDate(exportDateTimeReturn);
            setCountDown(countDownReturn);
        };
        fetch();
    }, []);
    //#endregion

    //#region Soft Delete
    const [isOpenDeleteModel, setOpenDeleteModel] = useState(false);

    const deleteReqBody = {
        id: id,
        updatedDateTimeClient: dayjs().format("YYYY-MM-DDTHH:mm:ss").toString(),
    };

    const handleDeleteForm = async () => {
        try {
            await userRequest2
                .delete("/api/uccrecords/delete", {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    data: JSON.stringify(deleteReqBody),
                })
                .then(function () {
                    setOpenDeleteModel(false);
                    navigate("/home");
                });
        } catch (error) {
            if (error.response && error.response.status === 404) {
                toast.error("This form has been deleted.");
                setOpenDeleteModel(false);
                navigate("/home");
            }
            if (error.response && error.response.status === 423) {
                toast.error("Unable to delete since this form has been submitted.");
                setOpenDeleteModel(false);
                navigate("/home");
            } else {
                console.error(error);
            }
        }
    };
    //#endregion
    const handleInputClick = () => {
        if (isDisableForm) {
            //toast.error("Form has already been submitted.")
            return
        }
    };

    const parsedArray = (value) => {
        const result = value ? JSON.stringify(value) : "";
        const parsedArray = result ? JSON.parse(result) : [];
        const filteredArray = Array.isArray(parsedArray)
            ? parsedArray.filter((item) => item !== null)
            : [];

        return filteredArray
    }

    /**
     * This is not the ideal implementation. This is why you use TS...
     * Also field === null and not just !check as value can be false
     */
    const formatField = (field, options) => {
        if (field === null) return "";

        const getLabel = (value) => {
            const foundOption = options.find(opt => value === opt.value);

            if (foundOption) {
                if (typeof foundOption.label === "string") {
                    return foundOption.label;
                } else {  // HTML element
                    if (typeof foundOption.label.props.children[0] === "string") {
                        return foundOption.label.props.children[0];
                    } else {  // HTML element
                        return foundOption.label.props.children[0].props.children;
                    }
                }
            }

            return "";
        };

        if (Array.isArray(field)) {
            const ret = [];

            const fields = parsedArray(field);
            fields.forEach(item => {
                ret.push(getLabel(item));
            })

            return ret.join(", ");
        } 

        return getLabel(field);
    };

    const generateData = () => {
        const nonEmptyCount = Object.keys(fields).reduce((count, key) => {
            const value = values[key];

            if (
                value !== null &&
                value !== "" &&
                value !== false &&
                !(Array.isArray(value) && value.length === 0)
            ) {
                return count + 1;
            }
            return count;
        }, 0);

        let totalFields = Object.keys(fields).length;

        if (acuteClick === false) {
            totalFields--;
        }

        if (totalFields <= 0) {
            completionPercentage = 0;
        } else {
            completionPercentage = Math.min(
                100,
                Math.round((nonEmptyCount / totalFields) * 100)
            );
        }

        const mappedData = {
            id: id,
            externalId: values.externalId ? values.externalId : "",
            episodeEndStatus: values.episodeEndStatus
                ? values.episodeEndStatus
                : null,
            isRegularGPAvailable:
                values.isRegularGPAvailable !== null
                    ? JSON.parse(values.isRegularGPAvailable)
                    : null,
            clinicalHandover: clinicalHandover,
            alternativePlaces: values.alternativePlaces
                ? values.alternativePlaces
                : null,
            otherReferralIdsJson: otherReferrals,
            conditionTypeIdsJson: conditionTypes,
            disabilityStatusIdsJson: disabilities,
            interpreterRequired:
                values.interpreterRequired !== null
                    ? JSON.parse(values.interpreterRequired)
                    : null,
            causeOfInjury: values.causeOfInjury ? values.causeOfInjury : null,
            languageIdsJson: languages,
            uccEntryPoint: values.uccEntryPoint ? values.uccEntryPoint : null,
            completionStatus: completionPercentage,
            isConsented: consent,
            version: values.version ? values.version : "",
            createdBy: localStorage.getItem("sub"),
            updatedDatetimeclient: formattedDate,
            treatmentEndTime3: values.treatmentEndTime3,
            isFormSubmitted: false,     // This get update outside of its method 
            dateOfPresentation: dateOfpresentationForDatabase
                ? dateOfpresentationForDatabase.format("YYYY-MM-DDTHH:mm:ss")
                : null,
        };

        return mappedData;
    }

    const savePatientDataToDatabase = async (submitForm = false) => {
        let postResponse;
        setDisableForm(true);

        // #region First time created or consented
        if (!id) {
            const postData = {
                externalId: patientId,
                createdDateTimeClient: formattedDate,
            };

            (values.linkageId) && (postData.linkageId = values.linkageId)

            try {
                postResponse = await userRequest2.post(`/api/uccrecords`, postData);
            } catch (error) {
                console.error("Error creating new record:", error);
                return;
            }

            // Important to set the date for Backdate
            dateOfpresentationForDatabase = formattedDate;
            const dataBody = {
                id: postResponse.data.id,
                updatedDateTimeClient: formattedDate,
                dateOfPresentation: formattedDate,
                isConsented: consent,
                createdBy: localStorage.getItem("sub"),
                version: postResponse.data.version,
            };

            try {
                await userRequest2.put(`/api/uccrecords/update`, dataBody);
            } catch (error) {
                console.error("Error updating new record:", error);
                return;
            }

            dispatch(setPreviousRouteOfForm("patientFromSearch"));
            window.location.replace(
                `/form/${encodeURIComponent(patientId)}/${postResponse.data.id}`
            );
        }
        //#endregion

        // If form has been submitted then we don't want to send
        if (values.isFormSubmitted) {
            return;
        }

        //#region Add to DB
        const mappedData = generateData();
        // Just a check that if submitting, the key is set to true
        (submitForm) && (mappedData.isFormSubmitted = true);

        //console.log("Saving Data", mappedData)
        try {
            await userRequest2
                .put(`/api/uccrecords/update`, mappedData)
                .then(function (response) {
                    dispatch(updateVersion(response.data));
                });
        } catch (error) {
            console.log("Error", error)
            if (error.response && error.response.status === 409) {
                try {
                    await retryFetchData();
                    toast.error("Someone has updated the form.");
                    toast.error("Updated data retrieved");
                } catch (error) {
                    console.error("Error retrying fetchData:", error);
                }
            } else {
                console.error("Error saving data to ucc", error);
            }
        }
        //#endregion

        setDisableForm(false)
    };

    //#region Setter methods
    const calculateAge = (dob) => {
        if (dob === "0001-01-01T00:00:00") {
            return "-";
        }
        const birthDate = new Date(dob);
        const today = new Date();
        let age = today.getFullYear() - birthDate.getFullYear();

        const monthDiff = today.getMonth() - birthDate.getMonth();
        if (
            monthDiff < 0 ||
            (monthDiff === 0 && today.getDate() < birthDate.getDate())
        ) {
            age--;
        }
        return age;
    };

    const setNames = (firstName, surname) => {
        if (firstName) {
            if (surname) {
                return firstName + " " + surname;
            } else {
                return firstName;
            }
        } else if (surname) {
            return surname;
        }
        return "";
    };

    const setGender = (sex) => {
        if (sex === "F" || sex === "f" || sex === 2) {
            sex = "Female";
        } else if (sex === "M" || sex === "m" || sex === 1) {
            sex = "Male";
        } else if (sex === "X" || sex === "x" || sex === 3) {
            sex = "Other";
        } else {
            sex = "Not stated";
        }
        return sex;
    };

    const setIndigenousStatus = (status) => {
        if (!status) {
            return;
        } else if (status === 1) {
            return "Aboriginal but not Torres Strait Islander";
        } else if (status === 2) {
            return "Torres Strait Islander but not Aboriginal";
        } else if (status === 3) {
            return "Both Aboriginal and Torres Strait Islander";
        } else if (status === 4) {
            return "Neither Aboriginal nor Torres Strait Islander";
        } else if (status === 0 || status === 9) {
            return "Not Stated/inadequately described";
        }
        return status;
    };

    const setTimeOfPresentation = (timeString) => {
        let elements = timeString.split(",");
        timeString = elements[0];
        const hours = timeString.substr(0, 2);
        const minutes = timeString.substr(2, 2);
        const seconds = timeString.substr(4, 2);

        const formattedTime = `${hours}:${minutes}:${seconds}`;
        const date = new Date(`2000-01-01T${formattedTime}`);

        const formattedTime12Hour = date.toLocaleTimeString("en-US", {
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
            hour12: true,
        });
        const amPm = formattedTime12Hour.slice(-2).toLowerCase();
        return formattedTime12Hour.slice(0, -2) + amPm;
    };

    const capitalizeName = (name) => {
        const words = name.split(" ");
        const capitalizedWords = words.map(
            (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        );
        return capitalizedWords.join(" ");
    };
    //#endregion

    const fetchData = async () => {
        let patient = patientId
        const dateOptions = { year: 'numeric', month: 'numeric', day: 'numeric' };
        let formData = {
            externalId: "",
            episodeEndStatus: null,
            isRegularGPAvailable: null,
            clinicalHandover: [],
            alternativePlaces: null,
            otherReferralIdsJson: [],
            conditionTypeIdsJson: [],
            disabilityStatusIdsJson: [],
            interpreterRequired: null,
            causeOfInjury: null,
            languageIdsJson: [],
            uccEntryPoint: null,
            completionStatus: 0,
            isConsented: null,
            tenantId: 0,
            updatedBy: "",
            createdBy: "",
            version: "",
            isSubmitted: null,
            dateOfPresentation: new Date().toLocaleDateString("en-GB", dateOptions),
            createdDateTimeClient: new Date().toLocaleDateString("en-GB", dateOptions)
        };
        setDisableForm(true);

        try {
            //#region Populate UCC Form from UCC API
            if (id) {
                setDisableForm(false);

                const response = await userRequest2.get(`/api/uccrecords/${id}`)
                const data = response.data
                patient = data.externalId
                const {
                    episodeEndStatus,
                    isRegularGPAvailable,
                    clinicalHandover,
                    alternativePlaces,
                    interpreterRequired,
                    causeOfInjury,
                    uccEntryPoint,
                    completionStatus,
                    tenantId,
                    updatedBy,
                    createdBy,
                    version,
                    isSubmitted,
                    isFormSubmitted,
                    isConsented,
                    treatmentEndTime3,
                    dateOfPresentation
                } = data;

                if (dateOfPresentation && dateOfPresentation !== "0001-01-01T00:00:00") {
                    const formattedDate = dayjs(new Date(dateOfPresentation));
                    console.log("Existing DOP", formattedDate)
                    setDateOfPresentation(formattedDate);
                }

                formData = {
                    externalId: data.externalId || "",
                    episodeEndStatus: episodeEndStatus !== null ? episodeEndStatus : null,
                    isRegularGPAvailable: isRegularGPAvailable !== null ? isRegularGPAvailable : null,
                    clinicalHandover: clinicalHandover 
                        ? clinicalHandover.split(",").map(Number)
                        : [],
                    alternativePlaces: alternativePlaces || null,
                    otherReferralIdsJson: data.otherReferralIdsJson
                        ? data.otherReferralIdsJson.split(",").map(Number)
                        : [],
                    conditionTypeIdsJson: data.conditionTypeIdsJson
                        ? data.conditionTypeIdsJson.split(",").map(Number)
                        : [],
                    disabilityStatusIdsJson: data.disabilityStatusIdsJson
                        ? data.disabilityStatusIdsJson.split(",").map(Number)
                        : [],
                    interpreterRequired: interpreterRequired !== null ? interpreterRequired : null,
                    causeOfInjury: causeOfInjury || null,
                    languageIdsJson: data.languageIdsJson
                        ? data.languageIdsJson.split(",")
                        : [],
                    uccEntryPoint: uccEntryPoint || null,
                    completionStatus: completionStatus || 0,
                    isConsented: consent || isConsented,
                    tenantId: tenantId || 0,
                    updatedBy: updatedBy || "",
                    createdBy: createdBy || "",
                    version: version || "",
                    isSubmitted: isSubmitted,
                    isFormSubmitted: isFormSubmitted,
                    treatmentEndTime3: treatmentEndTime3,
                    dateOfPresentation: dateOfPresentation &&
                        dateOfPresentation !== "0001-01-01T00:00:00"
                        ? new Date(dateOfPresentation).toLocaleDateString("en-GB", dateOptions)
                        : new Date().toLocaleDateString("en-GB", dateOptions),
                    createdDateTimeClient: data.createdDateTimeClient &&
                        data.createdDateTimeClient !== "0001-01-01T00:00:00"
                        ? new Date(data.createdDateTimeClient).toLocaleDateString("en-GB", dateOptions)
                        : new Date().toLocaleDateString("en-GB", dateOptions)
                };

                formData.causeOfInjury = causeOfInjury || null;

                setAcuteClick(formData.causeOfInjury !== null)
            }
            //#endregion

            if (formData.isFormSubmitted) {
                console.log("Form has been submitted. Disabling")
                setDisableForm(true);
            }

            //#region Populate Patient info from patientAPI
            const patientResponse = await userRequest.post(
                `/PatientAPI/GetPatient`,
                {
                    patientIds: patient
                }
            );

            const uccResponse = patientResponse.data[0];

            if (uccResponse) {
                const {
                    firstName,
                    surname,
                    linkageId,
                    dob,
                    sex,
                    address,
                    atsi,
                    medicareNo,
                    ethnicity,
                    time_of_presentation,
                } = uccResponse;

                const patientData = {
                    name: setNames(firstName, surname),
                    linkageId: linkageId,
                    age: dob ? calculateAge(dob) : "-",
                    gender: sex ? setGender(sex) : "-",
                    address: address || "-",
                    indigenousStatus: atsi ? setIndigenousStatus(atsi) : "-",
                    medicareNumber: medicareNo || "-",
                    ethnicity: ethnicity[0] ? ethnicity[0] : "-",
                    dateOfPresentation: dateOfpresentationForDatabase
                        ? dateOfpresentationForDatabase.format("YYYY-MM-DDTHH:mm:ss")
                        : null,
                    timeOfPresentation:
                        time_of_presentation && time_of_presentation[0]
                            ? setTimeOfPresentation(time_of_presentation)
                            : "-",
                };
                const retrivedPatientData = {
                    ...formData,
                    ...patientData
                }

                dispatch(setIsConsented(!uccResponse.optOut));
                dispatch(setPatientDatas(retrivedPatientData));
            }
            //#endregion
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const retryFetchData = () => {
        return new Promise((resolve, reject) => {
            setTimeout(async () => {
                try {
                    await fetchData();
                    resolve();
                } catch (error) {
                    reject(error);
                }
            }, 1000);
        });
    };

    //#region UseEffect
    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Only trigger update if consent has changed. Note: Add onInit check to prevent inital render
    useEffect(() => {
        if (onInit) {
            setOnInit(false);
            return;
        }

        const saveDataWithLatency = setTimeout(() => {
            console.log("Saving ...")
            savePatientDataToDatabase();
            setFormValuesChanged(false);
        }, 1000);

        return () => clearTimeout(saveDataWithLatency);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [consent]);

    //#region validate missing required form. only validate when the submitted btn has been clicked
    useEffect(() => {
        if (hasAttemptSubmit) {
            const data = generateData();
            const isFormValid = areValuesValid(data, noAcuteInjury);
            setIsMissing({});
            if (isFormValid.length > 0) {
                let missingFields = {};
                isFormValid.forEach((field) => {
                    missingFields[field] = true;
                });
                setIsMissing(missingFields);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values]);
    //#endregion

    useEffect(() => {
        if (updatedFormData?.conditionTypeIdsJson?.includes(1)) {
            setAcuteClick(true);
            noAcuteInjury = true;
        } else {
            setAcuteClick(false);
            noAcuteInjury = false;
        }

        // Update on form change
        if (formValuesChanged) {
            const saveDataWithLatency = setTimeout(() => {
                savePatientDataToDatabase();
                setFormValuesChanged(false);
            }, 1000);

            return () => clearTimeout(saveDataWithLatency);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updatedFormData, formValuesChanged]);
    //#endregion

    return (
        <>
            <div
                style={{ backgroundColor: "#ECEDEE", marginBottom: "0px",
                }}
            >
                <NavBar />
                <div style={styles.container}>
                    <Box>
                        <BackButton setAcuteClick={setAcuteClick} />
                        <Box style={styles.top_bar}>
                            <div style={styles.top_bar_heading}>Manage Patients</div>
                        </Box>
                        <Divider style={styles.horizon_line} />
                    </Box>

                    <Box display="flex" justifyContent="space-between" alignItems="center" style={styles.second_bar}>
                        <div style={styles.second_bar_heading}>Patient Form</div>

                        <Box style={{ paddingRight: "2%" }}>
                            {!(values.isFormSubmitted ||  consent === null || consent === undefined) && 
                                <Box display="flex" alignItems="center"
                                    onClick={() => setOpenDeleteModel(true)}>
                                    <DeleteOutline
                                        // name="CE1ID_DeleteIcon"
                                        style={{
                                            fill: "#17263E",
                                            width: "22px",
                                            height: "22px",
                                            curser: "pointer",
                                        }}
                                    />
                                    <div name="CE1ID_DeleteIcon" style={styles.icon_text}>
                                        Delete
                                    </div>
                                </Box>
                            }
                        </Box>
                    </Box>
                    <Formik initialValues={values} onSubmit={(values) => { }}>
                        <Form>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Grid container spacing={0.5}>
                                        <Grid item xs={12}>
                                            <div style={styles2.box_content2}>
                                                <div style={styles2.box_heading2}>
                                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                                        <Box xs={4} display="flex" alignItems="center">
                                                            <img style={styles2.profile_icon2} src={profileIcon}
                                                                alt="profile icon"/>
                                                            <span>{capitalizeName(values?.name)}</span>
                                                        </Box>
                                                        <Box style={{ paddingRight: "25px" }} item xs={4}>
                                                            <span>{values?.age}</span>y, &nbsp;
                                                            <span>{values?.gender}</span>
                                                        </Box>
                                                    </Box>
                                                </div>
                                                <div>
                                                    <Box>
                                                        <PatientInfo title="Address" value={values?.address || "-"}
                                                            toolTip={
                                                                <Tooltip id="tooltip1"
                                                                    title="Please ensure you have entered this field in the PMS"
                                                                    place="bottom"
                                                                    style={styles.tool_tip_style}
                                                                >
                                                                    <InfoOutlined
                                                                        name="CE1ID_MedicareNumberInfoIcon"
                                                                        style={styles.info_outlined_red}
                                                                    />
                                                                </Tooltip>}
                                                        />
                                                        <PatientInfo title="Medicare Number" value={values?.medicareNumber || "-"}
                                                            toolTip={
                                                                <Tooltip id="tooltip2"
                                                                    title="Please ensure you have entered this field in the PMS"
                                                                    place="bottom"
                                                                    style={styles.tool_tip_style}
                                                                >
                                                                    <InfoOutlined
                                                                        name="CE1ID_MedicareNumberInfoIcon"
                                                                        style={styles.info_outlined_red} />
                                                                </Tooltip>}
                                                        />
                                                        <PatientInfo title="Ethnicity" value={values?.ethnicity || "-"}
                                                            toolTip={
                                                                <Tooltip id="tooltip3"
                                                                    title="Please ensure you have entered this field in the PMS"
                                                                    place="bottom"
                                                                    style={styles.tool_tip_style}
                                                                >
                                                                    <InfoOutlined
                                                                        name="CE1ID_EthnicityInfoIcon"
                                                                        style={styles.info_outlined_red} />
                                                                </Tooltip>}
                                                        />
                                                        <PatientInfo title="Indigenous Status" value={values?.indigenousStatus || ""}
                                                            toolTip={
                                                                <Tooltip id="tooltip3"
                                                                    title="Please ensure you have entered this field in the PMS"
                                                                    place="bottom"
                                                                    style={styles.tool_tip_style}
                                                                >
                                                                    <InfoOutlined
                                                                        name="CE1ID_IndigenousStatusInfoIcon"
                                                                        style={styles.info_outlined_red} />
                                                                </Tooltip>}
                                                        />
                                                        <PatientInfo title="Date of the Presentation" value={
                                                            <div>
                                                                {/*<div>{values?.createdDateTimeClient || "-"}</div>*/}
                                                                <LocalizationProvider
                                                                    dateAdapter={AdapterDayjs}
                                                                    disabled={
                                                                        consent === null ||
                                                                        consent === undefined ||
                                                                        values.isFormSubmitted
                                                                    }
                                                                >
                                                                    <DatePicker
                                                                        slotProps={{
                                                                            textField: { size: "small" },
                                                                        }}
                                                                        sx={{
                                                                            "& .MuiOutlinedInput-root": {
                                                                                "& fieldset": {
                                                                                    borderRadius: "8px",
                                                                                    height: "20px",
                                                                                    paddingTop: "0px",
                                                                                    border: "none",
                                                                                },
                                                                                backgroundColor: "#FFFFFF",
                                                                                borderRadius: "8px",
                                                                            },
                                                                            "& .MuiInputBase-input": {
                                                                                padding: "8.5px 0"
                                                                            },
                                                                        }}
                                                                        value={dateOfPresentation}
                                                                        onChange={handleDateOfPresentation}
                                                                        format="DD/MM/YYYY"
                                                                        maxDate={dayjs(exportDate)}
                                                                        disableFuture
                                                                        disabled={
                                                                            consent === null ||
                                                                            consent === undefined ||
                                                                            values.isFormSubmitted
                                                                        }
                                                                    />
                                                                </LocalizationProvider>
                                                            </div>} />
                                                        <PatientInfo title="Time of the Presentation" value={values?.timeOfPresentation || "-"} />
                                                    </Box>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <History patientId={values.externalId} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={4} style={{ height: "100%" }}>
                                    <div style={styles3.box_content}>
                                        <div style={styles3.box_heading}>
                                            Patient Characteristics
                                        </div>
                                        <div>
                                            <Box spacing={2}>
                                                <Paper
                                                    sx={{
                                                        height: 596,
                                                        overflowY: "auto",
                                                        overflowX: 'hidden',
                                                        "&::-webkit-scrollbar": {
                                                            width: "6px",
                                                        },
                                                        "&::-webkit-scrollbar-thumb": {
                                                            backgroundColor: "#17263E",
                                                            height: "108px",
                                                            borderRadius: "4px",
                                                        },
                                                    }}
                                                >
                                                    <div style={styles3.container}>
                                                        {Object.entries(questionsMapping3).map(
                                                            ([
                                                                field,
                                                                {
                                                                    question,
                                                                    required,
                                                                    options,
                                                                    type,
                                                                    sup_text,
                                                                    info_text,
                                                                },
                                                            ]) => (
                                                                <Grid key={field} item xs={12}>
                                                                    <Grid container spacing={0}>
                                                                        <Grid item xs={11}>
                                                                            <div key={field}>
                                                                                <div style={styles3.question}>
                                                                                    {(required && !values.isFormSubmitted) && <span className="required">*</span>}
                                                                                    {question}
                                                                                    {!values.isFormSubmitted &&
                                                                                        <span style={{
                                                                                            color: (required && isMissing[field]) ? "red" : "rgba(0, 0, 0, 0.50)",
                                                                                            fontFamily: "Roboto",
                                                                                            fontSize: "14px",
                                                                                        }}>
                                                                                            {sup_text}
                                                                                        </span>}
                                                                                </div>
                                                                                <div
                                                                                    style={
                                                                                        field ===
                                                                                            "disabilityStatusIdsJson"
                                                                                            ? styles.questionOpt3
                                                                                            : styles.questionOpt3_1
                                                                                    }
                                                                                >
                                                                                    {values.isFormSubmitted
                                                                                        ? <Box style={{ color: "rgba(0, 0, 0, 0.5)" }}>{formatField(values[field], options)}</Box>
                                                                                        : <QuestionOptions3
                                                                                            field={field}
                                                                                            options={options}
                                                                                            type={type}
                                                                                            formData={values}
                                                                                            isDisableForm={isDisableForm}
                                                                                            isRequired={required}
                                                                                            isMissing={isMissing[field] || false}
                                                                                            handleInputClick={handleInputClick}
                                                                                            setFormData={(updatedFormData) => {
                                                                                                dispatch(setPatientDatas(updatedFormData));
                                                                                                setFormValuesChanged(true);
                                                                                            }}
                                                                                        />}
                                                                                </div>
                                                                            </div>
                                                                        </Grid>
                                                                        {(info_text && !values.isFormSubmitted) && (
                                                                            <Grid
                                                                                item
                                                                                xs={1}
                                                                                style={styles2.gray_info_icon2}
                                                                            >
                                                                                <Tooltip id="tooltip5"
                                                                                    title={info_text}
                                                                                    place="bottom"
                                                                                    style={styles.tool_tip_style} >
                                                                                    <InfoOutlined
                                                                                        style={styles.info_outlined_gray}
                                                                                    />
                                                                                </Tooltip>
                                                                            </Grid>
                                                                        )}
                                                                    </Grid>
                                                                </Grid>
                                                            )
                                                        )}
                                                    </div>
                                                </Paper>
                                            </Box>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={4}>
                                    <div style={styles1.box_content1}>
                                        <div style={styles1.box_heading1}>
                                            Patient Pathways and Clinical Details
                                        </div>
                                        <div>
                                            <Box spacing={2}>
                                                <Paper
                                                    sx={{
                                                        height: 596,
                                                        overflow: "auto",
                                                        overflowX: "hidden",
                                                        "&::-webkit-scrollbar": {
                                                            width: "6px",
                                                        },
                                                        "&::-webkit-scrollbar-thumb": {
                                                            backgroundColor: "#17263E",
                                                            height: "108px",
                                                            borderRadius: "4px",
                                                        },
                                                    }}
                                                >
                                                    <div style={styles1.container1}>
                                                        {Object.entries(questionsMapping1).map(
                                                            ([
                                                                field,
                                                                {
                                                                    question,
                                                                    required,
                                                                    options,
                                                                    type,
                                                                    sup_text,
                                                                    info_text,
                                                                },
                                                            ]) => (
                                                                <Grid key={field} item xs={12}>
                                                                    {!acuteClick && field !== "causeOfInjury" && (
                                                                        <Grid container spacing={0}>
                                                                            <Grid item xs={11}>
                                                                                <div key={field}>
                                                                                    <div style={styles1.question1}>
                                                                                        {(required && !values.isFormSubmitted) && <span className="required">*</span>}
                                                                                        {""}
                                                                                        {field !== "causeOfInjury" &&
                                                                                            question}
                                                                                        {!values.isFormSubmitted &&
                                                                                            <span style={{
                                                                                                color: (required && isMissing[field]) ? "red" : "rgba(0, 0, 0, 0.50)",
                                                                                                fontFamily: "Roboto",
                                                                                                fontSize: "14px",
                                                                                            }}>
                                                                                                {sup_text}
                                                                                            </span>}
                                                                                    </div>
                                                                                    <div style={{ paddingTop: "15px" }}>
                                                                                        {field !== "causeOfInjury" && <>
                                                                                            {values.isFormSubmitted
                                                                                                ? <Box style={{ color: "rgba(0, 0, 0, 0.5)" }}>{formatField(values[field], options)}</Box>
                                                                                                : <QuestionOptions1
                                                                                                    field={field}
                                                                                                    options={options}
                                                                                                    type={type}
                                                                                                    isDisableForm={isDisableForm}
                                                                                                    isRequired={required}
                                                                                                    isMissing={isMissing[field] || false}
                                                                                                    formData={values}
                                                                                                    handleInputClick={handleInputClick}
                                                                                                    setFormData={(updatedFormData) => {
                                                                                                        dispatch(setPatientDatas(updatedFormData));
                                                                                                        setFormValuesChanged(true);
                                                                                                    }}
                                                                                                />
                                                                                            }</>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </Grid>
                                                                            {(field !== "causeOfInjury" && info_text && !values.isFormSubmitted) && (
                                                                                <Grid
                                                                                    item
                                                                                    xs={1}
                                                                                    style={
                                                                                        styles2.gray_info_icon2
                                                                                    }
                                                                                >
                                                                                    <div styles={styles2.red_info_icon2}>
                                                                                        <Tooltip
                                                                                            id="tooltip6"
                                                                                            title={info_text}
                                                                                            place="bottom"
                                                                                            style={styles.tool_tip_style}
                                                                                        >
                                                                                            <InfoOutlined
                                                                                                style={field === "causeOfInjury"
                                                                                                    ? styles.info_outlined_yellow
                                                                                                    : styles.info_outlined_gray}
                                                                                            />
                                                                                        </Tooltip>
                                                                                    </div>
                                                                                </Grid>
                                                                            )}
                                                                        </Grid>
                                                                    )}
                                                                    {acuteClick && (
                                                                        <Grid container spacing={0}>
                                                                            <Grid item xs={11}>
                                                                                <div key={field}>
                                                                                    <div style={styles1.question1}>
                                                                                        {(required && !values.isFormSubmitted) && <span className="required">*</span>}
                                                                                        {question}
                                                                                        {!values.isFormSubmitted &&
                                                                                            <span style={{
                                                                                                color: (required && isMissing[field]) ? "red" : "rgba(0, 0, 0, 0.50)",
                                                                                                fontFamily: "Roboto",
                                                                                                fontSize: "14px",
                                                                                            }}>
                                                                                                {sup_text}
                                                                                            </span>}
                                                                                    </div>
                                                                                    <div style={{ paddingTop: "15px" }}>
                                                                                        {values.isFormSubmitted
                                                                                            ? <Box style={{ color: "rgba(0, 0, 0, 0.5)" }}>{formatField(values[field], options)}</Box>
                                                                                            : <QuestionOptions1
                                                                                                field={field}
                                                                                                options={options}
                                                                                                type={type}
                                                                                                isDisableForm={isDisableForm}
                                                                                                isRequired={required}
                                                                                                isMissing={isMissing[field] || false}
                                                                                                formData={values}
                                                                                                handleInputClick={handleInputClick}
                                                                                                setFormData={(updatedFormData) => {
                                                                                                    dispatch(setPatientDatas(updatedFormData));
                                                                                                    setFormValuesChanged(true);
                                                                                                }}
                                                                                            />}
                                                                                    </div>
                                                                                </div>
                                                                            </Grid>
                                                                            {(info_text && !values.isFormSubmitted) && (
                                                                                <Grid
                                                                                    item
                                                                                    xs={1}
                                                                                    style={styles2.gray_info_icon2}
                                                                                >
                                                                                    <div styles={styles2.red_info_icon2}>
                                                                                        <Tooltip
                                                                                            id="tooltip6"
                                                                                            title={info_text}
                                                                                            place="bottom"
                                                                                            style={styles.tool_tip_style}
                                                                                        >
                                                                                            <InfoOutlined
                                                                                                style={field === "causeOfInjury"
                                                                                                    ? styles.info_outlined_yellow
                                                                                                    : styles.info_outlined_gray}
                                                                                            />
                                                                                        </Tooltip>
                                                                                    </div>
                                                                                </Grid>
                                                                            )}
                                                                        </Grid>
                                                                    )}
                                                                </Grid>
                                                            )
                                                        )}
                                                    </div>
                                                </Paper>
                                            </Box>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </Form>
                    </Formik>
                    <div className="space-x" style={styles.button}>
                        {/*<div*/}
                        {/*    style={{*/}
                        {/*        display: "flex",*/}
                        {/*        gap: "4px",*/}
                        {/*        alignItems: "center",*/}
                        {/*        justifyContent: "flex-end",*/}
                        {/*        cursor: "pointer",*/}
                        {/*    }}*/}
                        {/*    >*/}
                        {/*    <Tooltip*/}
                        {/*        id="toolTip"*/}
                        {/*        place="left"*/}
                        {/*        style={{*/}
                        {/*            zIndex: "9999",*/}
                        {/*            padding: "6px 16px",*/}
                        {/*            alignItems: "center",*/}
                        {/*            gap: "12px",*/}
                        {/*            lineHeight: "143%",*/}
                        {/*            color: "#FFFF",*/}
                        {/*            backgroundColor: "#1A485E",*/}
                        {/*        }} />*/}
                        {/*    <p*/}
                        {/*        data-tooltip-id="toolTip"*/}
                        {/*        data-tooltip-content="Next Data Submission Date"*/}
                        {/*    >*/}
                        {/*        <InfoOutlined*/}
                        {/*            style={{*/}
                        {/*                width: "20px",*/}
                        {/*                height: "20px",*/}
                        {/*                fill: "rgba(0, 0, 0, 0.50)",*/}
                        {/*                marginTop: "2px",*/}
                        {/*            }}*/}
                        {/*        />*/}
                        {/*    </p>*/}

                        {/*    <p style={{ padding: "5px" }}>*/}
                        {/*        {exportDate?.format("DD/MM/YYYY")} &nbsp;{" "}*/}
                        {/*        {countDown === 0 ? "(Today)" : `(${countDown} Days)`}*/}
                        {/*    </p>*/}
                        {/*</div>*/}

                        <CustomButton name="CE1ID_SaveAndCloseButton" color="secondary"
                            onClick={() => {
                                (!isDisableForm) && (savePatientDataToDatabase())
                                navigate("/home")
                            }}
                        >
                            {isDisableForm ? "CLOSE" : "SAVE AND CLOSE"}
                        </CustomButton>

                        {!values.isFormSubmitted &&
                            <CustomButton name="CE1ID_SubmitButton" disabled={isDisableForm}
                                onClick={() => {
                                    setHasAttemptSubmit(true);
                                    const mappedData = generateData();
                                    const isFormValid = areValuesValid(mappedData, acuteClick) || [];
                                    setIsMissing({});
                                    if (isFormValid.length > 0) {
                                        let missingFields = {};
                                        isFormValid.forEach((field) => {
                                            missingFields[field] = true;
                                        });
                                        setIsMissing(missingFields);
                                        //setShowErrorModal(true)
                                        return;
                                    }

                                    setShowConfirmModal(true)
                                }}
                            >
                                SUBMIT
                            </CustomButton>}

                    </div>
                </div>

                {/*<SubmitErrorModal
                    isOpen={showErrorModal}
                    handleOpen={() => setShowErrorModal(true)}
                    handleClose={() => setShowErrorModal(false)}
                    savePatientData={savePatientDataToDatabase}
                />*/}

                <SubmitConfirmModal
                    isOpen={showConfirmModal}
                    handleOpen={() => setShowConfirmModal(true)}
                    handleClose={() => setShowConfirmModal(false)}
                    savePatientData={savePatientDataToDatabase}
                />

                <SoftDeleteModal
                    isOpen={isOpenDeleteModel}
                    handleOpen={() => setOpenDeleteModel(true)}
                    handleClose={() => setOpenDeleteModel(false)}
                    onClick={handleDeleteForm}
                />
            </div>
        </>
    );
}

export default PatientFormPage;
