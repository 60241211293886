import { Grid } from '@mui/material'

const PatientInfo = ({
    title,      // string
    value,      // string | JSX
    toolTip = null,    // Tooltip
}) => {
    return (
        <Grid container spacing={0} style={{ paddingLeft: "10px" }}>
            <Grid item xs={12} md={6} style={{ margin: "12px 0", fontWeight: "600", }}>
                {title}:
            </Grid>
            <Grid item xs={12} md={6} style={{ display: "flex", alignItems: "center", paddingRight: "16px", justifyContent: "space-between", gap: "8px" }}>
                <div>{value || ""}</div>
                {(toolTip && value === "-") &&
                    <div style={{ cursor: "pointer", marginTop: "6px" }}>
                        {toolTip}
                    </div>}
            </Grid>
        </Grid>
    )
}

export default PatientInfo
