import { Grid } from '@mui/material'
import { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { userRequest2 } from 'userRequest'

const History = (patientId) => {
    const [data, setData] = useState([])
    const { id } = useParams()

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const postData = {
                    patientExternalIds: [patientId.patientId],
                    pageNumber: 1,
                    pageSize: 0,
                }
                const response = await userRequest2.post(
                    `/api/uccrecords/search`,
                    postData
                )
                const data = response.data.items
                const dateList = data.map((item) => ({
                    id: item.id,
                    externalId: item.externalId,
                    date: formatDate(item.dateOfPresentation),
                }))
                setData(dateList)
            } catch (error) {
                console.error('Error fetching data:', error)
            }
        }
        fetchUserData()
    }, [patientId])

    const formatDate = (utcDate) => {
        const options = { day: 'numeric', month: 'long', year: 'numeric' }
        const localDate = new Date(utcDate)
        return localDate.toLocaleDateString('en-AU', options)
    }

    return (
        <>
            <div style={styles.box_content}>
                <div style={styles.box_heading}>
                    <Grid container spacing={0}>
                        <Grid style={styles.patient_name}>
                            Patient History at Urgent Care Service
                        </Grid>
                    </Grid>
                </div>
                <div>
                    <Grid container spacing={0}>
                        <Grid
                            sx={{
                                minHeight: '0px',
                                maxHeight: '248px',
                                overflow: 'auto',
                                overflowX: 'hidden',
                                '&::-webkit-scrollbar': {
                                    width: '6px',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: '#1A485E',
                                    borderRadius: '4px',
                                },
                            }}
                            style={{
                                ...styles.dateContainer,
                            }}
                            item
                            xs={12}
                        >
                            {data.map((item) => (
                                <div key={item.id}>
                                    <a
                                        href={`/form/${encodeURIComponent(item.externalId)}/${item.id}`}
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        style={{
                                            display: 'contents',
                                            textDecoration: 'none',
                                            color: 'inherit',
                                            cursor: 'pointer',
                                            pointerEvents:
                                                item.id.toString() === id ? 'none' : 'auto',
                                        }}
                                        key={item.id}
                                    >
                                        <p
                                            style={{
                                                ...styles.field,
                                                fontWeight:
                                                    item.id.toString() === id ? 'bold' : 'normal',
                                            }}
                                            key={item.id}
                                        >
                                            {item.date}
                                        </p>
                                    </a>
                                    {data.length !== data.length - 1 && (
                                        <div
                                            style={{
                                                height: '4px',
                                                background: '#ECEDEE',
                                            }}
                                        />
                                    )}
                                </div>
                            ))}
                        </Grid>
                    </Grid>
                </div>
            </div>
        </>
    )
}

const styles = {
    dateContainer: {
        marginBottom: "4px",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
    box_heading: {
        borderRadius: "8px 8px 0px 0px",
        background: "#1A485E",
        fontSize: "20px",
        color: "white",
        fontFamily: "Roboto",
        fontWeight: "700",
    },

    box_content: {
        backgroundColor: "#FFFF",
        marginTop: "16px",
        boxShadow:
            "0px 4px 6px -1px rgba(0, 0, 0, 0.3), 0px 5px 8px 0px rgba(0, 0, 0, 0.22)",
        borderRadius: "0px 0px 8px 8px",
    },

    patient_name: {
        padding: "11px 0px 11px 17px",
        margineLeft: "10px",
        position: "relative",
    },

    field: {
        paddingLeft: "10px",
        textAlign: "center",
        textDecoration: "none",
        color: "black",
        letterSpacing: "0.15px",
        height: "14px",
    },

    last_field: {
        fontWeight: "600",
        paddingLeft: "10px",
        paddingBottom: "20px",
    },
};

export default History
