import { useState } from 'react'
import { Box, Button, Modal } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setPreviousRouteOfForm } from 'Services/actions'
import { RotatedErrorOutlineIcon } from './CustomModal'
import { CustomButton } from './CustomButton'

const SearchModal = ({ isOpen, data, onClose }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [isModalOpen, setIsModalOpen] = useState(isOpen)

    const closeModal = () => {
        onClose()
        setIsModalOpen(false)
    }

    const handleContinueToExistingForm = () => {
        dispatch(setPreviousRouteOfForm('patientFromSearch'))
        navigate(
            `/form/${encodeURIComponent(data?.items[0].externalId)}/${data?.items[0].id
            }`
        )
        onClose()
    }

    const handleStartNewForm = () => {
        dispatch(setPreviousRouteOfForm('patientFromSearch'))
        navigate(`/form/${encodeURIComponent(data?.items[0].externalId)}`)
    }

    return (
        <>
            <Modal open={isModalOpen} onClose={closeModal}>
                <Box style={styles.modal}>
                    <Box style={styles.modalHeader}>
                        <p style={styles.heading}>Patient Search</p>
                        <Button
                            name="CE1ID_PatientSearchCloseButton"
                            onClick={closeModal}
                            style={styles.closeIcon}
                        >
                            <CloseIcon
                                id="closeButton"
                                name="CE1ID_PatientSearchCloseButton"
                                sx={{ color: "#FFFFFF" }}
                                style={{ zIndex: '-1' }}
                            />
                        </Button>
                    </Box>

                    <>
                        <div style={styles.contentContainer}>
                            <RotatedErrorOutlineIcon />
                            <p style={styles.modalBody}>
                                A form has been created for this patient earlier today. Do you
                                want to continue adding to the same form?
                            </p>
                        </div>
                        <div style={styles.buttonContainer}>
                            <CustomButton color="secondary"
                                name="CE1ID_NewFormButton"
                                onClick={handleStartNewForm}
                            >
                                New Form
                            </CustomButton>
                            <CustomButton
                                name="CE1ID_SameFormButton"
                                onClick={handleContinueToExistingForm}
                            >
                                Same Form
                            </CustomButton>
                        </div>
                    </>
                </Box>
            </Modal>
        </>
    )
}

const styles = {
    modalBody: {
        color: '#1A485E',
        fontSize: '20px',
        fontWeight: '600',
        lineHeight: '30px',
        letterSpacing: '0.15px',
        padding: '0px',
        margin: '0px',
    },
    heading: {
        padding: '0px',
        margin: '0px',
        fontSize: '20px',
        fontWeight: '700',
        color: '#FFFFFF',
        lineHeight: '24px',
        letterSpacing: '0.15px',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'end',
        margin: '47px 39px 36px 0px',
        gap: '14px',
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'row',
        margin: '40px 46px 0px 24px',
        alignItems: 'center',
        gap: '19px',
    },
    modal: {
        position: 'absolute',
        top: '184px',
        left: '50%',
        transform: 'translateX(-50%)',
        minWidth: '53vw',
        maxHeight: 'calc(100vh - 40px)',
        overflow: 'auto',
        backgroundColor: '#fff',
        outline: 'none',
        borderRadius: '8px',
    },
    modalHeader: {
        padding: '19px 22px 11px 24px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: '#1A485E',
    },
    closeIcon: {
        padding: 0,
        fontSize: '32px',
        minWidth: 'auto',
        width: '30px',
        height: '30px',
        fontWeight: '500',
        zIndex: '1',
    },
}

export default SearchModal
