import React from "react";
import {
    Select,
    MenuItem,
} from "@mui/material";
import {
    styles,
} from "utils/QuestionsMapping";

//required = { true}
//onClick = {() => handleInputClick()}
//value = { formData[field]}
//onChange = {(e) => handleChange(e)}
export const ClinicalSelect = ({
    field,
    formData,
    options,
    control,                // Comes from in Form
    disabled,               // Use to disable the radio button
    checked = false,        // Use to see if the radio button is checked
    onRadioClick,
    isMissing,
    className,
    ...props
}) => {
    return (
        <Select
            error={isMissing}
            disabled={disabled}
            value={formData[field] || ''}
            displayEmpty={true}
            renderValue={(value) => {
                const selectedOption = options.find(
                    (option) => option.value === value
                );
                return selectedOption ? selectedOption.label : "Select one";
            }}
            style={styles.menu_props}
            MenuProps={{
                PaperProps: {
                    style: {
                        maxHeight: "200px",
                    },
                },
            }}
            {...props}
        >
            {options.map(({ value, label }) => (
                <MenuItem key={value} value={value} style={{ padding: 0 }}>
                    <div name={`CE1ID_${field}${value}`} style={styles.label_menu_item}>
                        {label}
                    </div>
                </MenuItem>
            ))}
        </Select>
    )
}