import { questionsMapping1, questionsMapping3 } from "./QuestionsMapping";

export const processFormData = (value) => {
    let result;
    result = value ? JSON.stringify(value) : "";
    const parsedArray = result ? JSON.parse(result) : [];
    const filteredArray = Array.isArray(parsedArray)
        ? parsedArray.filter((item) => item !== null)
        : [];
    result = filteredArray.join(",");
    return result;
}

export const isEmpty = (value) => value === null || value === undefined || value === "" || value === [];

// Return a list of missing if invalid
export const areValuesValid = (data, acuteClick) => {
    let requiredFields = [];
    for (const key in questionsMapping1) {
        if (questionsMapping1[key].required && questionsMapping1[key].required === true) {
            requiredFields.push(key);
        }
    }

    for (const key in questionsMapping3) {
        if (questionsMapping3[key].required && questionsMapping3[key].required === true) {
            requiredFields.push(key);
        }
    }

    // Define a function to check if a value is valid
    let missingFields = [];
    for (const field of requiredFields) {
        if (!data.hasOwnProperty(field) || isEmpty(data[field])) {
            missingFields.push(field);
        } 
    }

    return missingFields;
}
